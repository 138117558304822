<template>
  <div class="loan_item">
    <div class="inner inner1 flex ai-c">
      <!-- <div>
        <h1>Sobre nosotros</h1>
        <p>
          Caudalsube es una aplicación financiera en línea en la que los usuarios pueden
          solicitar préstamos personales de hasta  $ 30,000 en cualquier momento y lugar.
          Ofrece servicios de préstamo flexibles y buenos. Ofrece moneda completa en
          línea a través de plataformas seguras, sin ninguna hipoteca.
        </p>
        <p>
          Nuestra empresa ofrece préstamos financieros instantáneos y soluciones de
          préstamos personales. No solo apoyamos su consumo personal (como
          atención médica personal, primas de seguro, inversiones, gastos de boda,
          viajes, transporte, gastos educativos, etc.), sino que también le ayudamos
          a obtener préstamos con flexibilidad y resolver sus necesidades de
          financiación a través de métodos técnicos innovadores.
        </p>
      </div> -->
    </div>
    <div class="inner inner2">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <h1>Perfil de la empresa</h1>
        <div class="left_cell">
          <p class="inner1_text">MonedaCome es una aplicación que ofrece servicio de préstamo online en forma de préstamos personales
            para brindar ayuda urgente a los usuarios, los procesos son sencillos y seguros. MonedaCome ofrece el
            servicio financiero de préstamos rápidos en efectivo en línea sin garantiza en cualquier momento. Nuestro
            cliente obtendrá un monto de préstamo inicial la primera vez y el crédito aumentará si el reembolso se
            realiza a tiempo. Un crédito más alto dará lugar a un monto de préstamo mayor y a un interés más bajo
            para el próximo préstamo.
          </p>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <!-- <div class="title">
        <h3>Nuestro principio de servicio</h3>
      </div>
      <div class="flex">
        <div v-for="(item,index) in inner3items" :key="index" class="content">
          <div class="flex fd-c">
            <img :src="item.img">
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="inner inner4">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <h1>Misión y Visión</h1>
        <div class="left_cell">
          <p class="inner1_text">Utilizar tecnologías innovadoras para proporcionar servicios financieros más flexibles, confiables, convenientes
y eficientes a los grupos de bajos ingresos y vulnerables.<br>
Utilizar tecnologías innovadoras para proporcionar servicios financieros más flexibles, confiables, convenientes
y eficientes a los grupos de bajos ingresos y vulnerables.
          </p>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="title">
        <h3>Contáctanos</h3>
        <p>Cualquier dudas o inconveniencias que ocurran durante proceso de solicitar préstamo, estamos a sus ordenes por correo.</p>
      </div>
      <div class="flex items jc-sb">
        <div v-for="(item,index) in inner5items" :key="index" class="content jc-c ai-c">
          <div class="flex fd-c ai-c">
            <img :src="item.img">
            <!-- <h1>{{ item.txt }}</h1> -->
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner3items: [
        { img: require('@/assets/images/people1.png'), txt: 'Satisfacción del cliente', txt2: 'Queremos resolver una necesidad de dinero urgente con la mayor rapidez, eficacia y \n buen trato.' },
        { img: require('@/assets/images/people2.png'), txt: 'Transparencia', txt2: 'Recuerda que nuestra tasa de interés siempre es visible, nuestros préstamos son \n sin comisiones, letras chiquitas o costos ocultos. \n El único requisito es pagarlo en un lapso de 1 a \n 9 meses.' },
        { img: require('@/assets/images/people3.png'), txt: 'Responsabilidad', txt2: 'Sugerimos a nuestros clientes que pidan un préstamo rápido, solo si es su mejor opción de financiamiento.' }
      ],
      inner_4items: [
        { img: require('@/assets/images/inner_4_3.png'), title: 'Misión', list2: [{ txt: 'Utilizar tecnologías innovadoras para proporcionar servicios financieros más flexibles, confiables, convenientes y eficientes a los grupos de bajos ingresos y vulnerables.' }] },
        { img: require('@/assets/images/inner_4_4.png'), title: 'Visión', list2: [{ txt: 'Con el fin de resolver el problema del uso de los fondos y mejorar la calidad de vida de todos, nos comprometemos a proporcionar los mejores servicios de tecnología financiera a la sociedad.' }] }
        // { img: require('@/assets/images/inner_4_3.png'), title: 'Préstamo rápido', list2: [{ txt2: 'Una vez aprobado el sistema, el dinero se transferirá \n directamente a su tarjeta bancaria.' }] }
      ],
      inner6ItemLeftList: [
        { title: 'E-mail:pesogoviphelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: 'Address:170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA', img: require('@/assets/images/address.png') },
        { title: 'Service Hours : 9:00 - 17:00', img: require('@/assets/images/time.png') },
        { title: 'SEC Registration No.CS201911897;CERTIFICATE OF AUTHORITY NO.3037' }
      ],
      inner5items: [
        { img: require('@/assets/images/loanImg/email.png'), txt: 'E-mail:', txt2: 'ayuda@monedacome.com' },
        { img: require('@/assets/images/loanImg/address.png'), txt: 'Dirección de la oficina:', txt2: 'Montecito No. 30, Floor 30, Office 17, \n Colonia Napoles Mexico City, Mexico D.F., \n 3810 Mexico' },
        { img: require('@/assets/images/loanImg/time.png'), txt: 'Tiempo laboral：', txt2: 'De lunes a viernes 9:00-17:00' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px calc((100% - 1100px) / 2) ;
    h3{
      font-size: 18px;
    }
    .content{
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 30px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner1{
    background: url('~@/assets/images/loan_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 480px;
    display: flex;
    text-align: left;
    div{
      border-radius: 20px;
      padding: 0 550px 0 0;
      opacity: 0.8;
      h1{
        font-size: 40px;
        line-height: 140px;
        color: #FFFFFF;
      }
      p{
        color: #FFFFFF;
        font-size: 14px;
        text-align: left;
        width: 100%;
        line-height: 30px;
        padding: 0 0 60px ;
      };
    }
  }
  .inner2{
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        margin: 25px 0 40px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
        // padding: 0 0 60px;s
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner3{
    background: url('~@/assets/images/loan_bg2.png') no-repeat;
    background-size: 100% 100%;
    height: 400px;
    display: flex;
    .title{
      text-align: center;
      margin-bottom: 50px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: white;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 35%;
      margin: 0 10px;
      padding-bottom: 20px;
      text-align: center;
      color: black;
      background: white;
      border-radius: 20px;
      div{
        height: 100%;
      }
      img{
        width: 100%;
        border-radius: 20px 20px 0 0;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #666666;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
  .inner4{
    background: #F7F7F7;
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        // margin: 25px 0 40px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner5{
    position: relative;
    padding:100px calc((100% - 1300px) / 2) ;
    background: #FFFFFF;
    white-space: pre-line;
    text-align: center;
    .title{
      margin-bottom: 50px;
      padding-left: 10px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: #333333;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 30%;
      margin: 0 10px;
      padding: 30px 0;
      text-align: center;
      color: white;
      border-radius: 10px;
      background: #214BC0;
      div{
        // height: 100%;
      }
      img{
        width: 30px;
        margin-bottom: 10px;
      }
      h1{
        margin-top: 20px;
        font-size: 18px;
        line-height: 50px;
      }
      p{
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 24px  0px;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 100px;
      color: white;
      padding: 10px 20px;
      flex-direction: column;
      background: #063EA4 !important;
      div{
        padding: 0 20px !important;
      }
    }
  .inner2{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
  .inner3{
    min-width: calc(100vw - 40px) !important;
    width: calc(100vw ) !important;
    margin: 0 auto !important;
    height: 100px;
    h3{
      font-size: 25px;
      text-align: center;
      margin-bottom: 20px;
    }
    .flex{
      flex-direction: column;
    }
    .content{
      width: calc(100% - 40px);
      margin-bottom: 50px;
      margin: 0 auto 40px;
      img{
        width: calc(100vw - 40px) !important;
      }
      div{
        width: calc(100vw - 40px) !important;
        p{
          font-size: 12px;
        }
      }
    }
    .title{
      width: 100%;
      margin-bottom: 20px;
    }
    .inner5_item{
      display: flex;
      flex-direction: column;
    }
  }
  .inner4{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
    .inner5{
      padding: 50px calc((100% - 1300px) / 2);
      .items{
        flex-direction: column;
        .content{
          width: 80vw;
          margin: 0 auto 20px;
        }
      }
    }
  }
}
</style>
